import { Input } from "@/components/InputText/InputText";
import { useI18n, useValidateEmail } from "@jog/react-components";
import { dispatchInputChange, navigate } from "@jog/shared";
import { AlertBox, Button, Container, LogoLoader, Modal, ReCaptchaDisclaimer, WhiteBox } from "components";
import { observer } from "mobx-react";
import { useSession } from "next-auth/react";
import React, { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useStores } from "stores";
import LoginForm from "./LoginForm";
import LoginResetPassword from "./LoginResetPassword";
const LoginContentComponent = () => {
    const { $t } = useI18n();
    const { userStore } = useStores();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isUserEmailNotRegistered, setIsUserEmailNotRegistered] = useState(false);
    const [email, setEmail] = useState("");
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [availableError, setAvailableError] = useState(null);
    const [isResetPasswordEmailNotRegistered, setIsResetPasswordEmailNotRegistered] = useState(false);
    const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(null);
    const [isResetPasswordEmailSent, setIsResetPasswordEmailSent] = useState(false);
    const getReCaptchaToken = useCallback(async (action) => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        return await executeRecaptcha(action);
    }, [executeRecaptcha]);
    const goToStepOne = async () => {
        setShowLogin(false);
        setEmail("");
        // Trigger check if Enter key is pressed
        setTimeout(() => {
            var _a;
            (_a = emailElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        }, 100);
    };
    const checkIfUserEmailIsRegistered = useCallback(async () => {
        var _a, _b;
        const token = await getReCaptchaToken("validateEmail");
        try {
            const { available: isNotRegistered, message } = await userStore.checkIfUserExists(email, token);
            if (message) {
                setAvailableError({ message });
                return;
            }
            setAvailableError(null);
            setIsUserEmailNotRegistered(isNotRegistered);
            const lowerCaseEmail = String(email).toLowerCase();
            if (isNotRegistered) {
                userStore.unregisteredEmail = lowerCaseEmail;
                navigate(`/signup`);
            }
            else {
                setShowLogin(true);
                setTimeout(() => {
                    dispatchInputChange('input[name="username"]', lowerCaseEmail);
                }, 0);
                // Focus on Password field
                setTimeout(() => {
                    const passwordInput = document.getElementsByName("password");
                    if (passwordInput[0])
                        passwordInput[0].focus();
                }, 100);
            }
        }
        catch (e) {
            setAvailableError({
                error: (_a = e.body) === null || _a === void 0 ? void 0 : _a.error,
                message: "Captcha validation was not successful",
                userCountryCode: (_b = e.body) === null || _b === void 0 ? void 0 : _b.country,
            });
        }
    }, [email, getReCaptchaToken, userStore]);
    const [captchaFail, setCaptchaFail] = useState("");
    const checkEmailForPasswordReset = useCallback(async (email) => {
        const token = await getReCaptchaToken("validateEmail");
        setCaptchaFail("");
        const { available: isNotRegistered, message } = await userStore.checkIfUserExists(email, token);
        if (message) {
            setCaptchaFail(message);
            return;
        }
        if (isNotRegistered) {
            setIsResetPasswordEmailNotRegistered(true);
        }
        else {
            const response = await userStore.sendEmailForForgotPassword(email);
            if (response) {
                setIsResetPasswordEmailSent(true);
            }
        }
    }, [getReCaptchaToken, userStore]);
    const listenToEnter = useCallback((event) => {
        if (event.key === "Enter" && !isEmailInvalid) {
            checkIfUserEmailIsRegistered();
        }
    }, [checkIfUserEmailIsRegistered, isEmailInvalid]);
    useEffect(() => {
        var _a;
        const url = new URL(window.location.href);
        const paramResetPasswordSuccess = url.searchParams.get("resetPasswordSuccess");
        (_a = emailElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        if (paramResetPasswordSuccess !== null) {
            setIsResetPasswordSuccess(paramResetPasswordSuccess);
        }
        // Collect param values if coming from the special password reset email link (example.com/login?email=abc@gmail.com&rp=true)
        const paramEmail = url.searchParams.get("email");
        const paramResetPassword = url.searchParams.get("rp");
        if (paramEmail && paramResetPassword) {
            setEmail(paramEmail);
            setShowModal(true);
        }
    }, [listenToEnter]);
    const { validateEmail } = useValidateEmail();
    const getEmailValue = useCallback((e) => {
        setEmail(e.target.value);
        setIsEmailInvalid(!validateEmail(e.target.value));
        setIsBtnDisabled(!validateEmail(e.target.value));
    }, [validateEmail]);
    const toggleModal = (show) => {
        setShowModal(!showModal);
        if (!show) {
            setIsResetPasswordEmailNotRegistered(false);
            setIsResetPasswordEmailSent(false);
        }
    };
    const emailElement = React.useRef(null);
    const session = useSession();
    useEffect(() => {
        if (session.status === "authenticated") {
            navigate(`/my-account`);
        }
    }, [session.status]);
    if (session.status === "authenticated") {
        return (<div className="mt-5 text-base">
                <Container className="mb-15">
                    <LogoLoader />
                </Container>
            </div>);
    }
    return (<div className="mt-5 text-base">
            <Container className="mb-15">
                {userStore.isLoading && <LogoLoader />}
                {isResetPasswordSuccess === "false" ? (<AlertBox type="error" text={$t("The link you have used is not (more) valid")}/>) : ("")}
                {isResetPasswordSuccess === "true" ? (<AlertBox text={$t("Lucky you can now log in with your new password")}/>) : ("")}
                {availableError && <AlertBox text={availableError.message}/>}
                <WhiteBox>
                    {!isUserEmailNotRegistered && !showLogin && (<>
                            <h1 className="my-5 text-3.5xl font-bold">{$t("Log in / Register")}</h1>
                            <p className="mb-5.5">
                                {$t(`Enter your e-mail address. We will then check whether you are JC FRIEND and / or already have a webshop account..`)}
                            </p>

                            <div className="flex flex-col flex-wrap items-center lg:flex-row">
                                <div className="w-full basis-full lg:basis-3/12">
                                    <label htmlFor="emailOne">{$t("E-mail address")} *</label>
                                </div>
                                <div className="w-full basis-full lg:basis-7/12">
                                    <div className="flex items-center">
                                        <Input className="w-full" ref={emailElement} id="emailOne" onChange={getEmailValue} onKeyUp={listenToEnter} onPaste={getEmailValue} autoComplete="email" type="email"/>
                                    </div>
                                    {isEmailInvalid && (<div className="mt-1.25 text-right text-13">{$t("Invalid email address")}</div>)}
                                </div>
                            </div>
                            <div className="flex w-full">
                                <div className="mt-5 flex w-full basis-full justify-end lg:basis-10/12">
                                    <Button className="hover:!bg-orange-main" bgColor="#809A7B" color="#fff" onClick={checkIfUserEmailIsRegistered} disabled={isBtnDisabled}>
                                        {$t("Log in / Register")}
                                    </Button>
                                </div>
                            </div>
                        </>)}

                    {!isUserEmailNotRegistered && showLogin && (<LoginForm emailForCheck={email} isCheckout={false} toggleModal={() => toggleModal(true)} goToStepOne={goToStepOne}/>)}
                    <ReCaptchaDisclaimer />
                </WhiteBox>
            </Container>

            {showModal && (<Modal headerTitle={$t("Reset password")} hideModal={() => toggleModal(false)} width="50%">
                    {isResetPasswordEmailNotRegistered && (<AlertBox type="warning" text={$t("WE DO NOT HAVE AN ACCOUNT FOR THIS EMAIL ADDRESS, THEREFOR CREATE A NEW ACCOUNT")}/>)}
                    {captchaFail && <AlertBox type="warning" text={$t(captchaFail)}/>}
                    {isResetPasswordEmailSent && (<AlertBox text={$t("WE HAVE SENT YOU AND E-MAIL STATING HOW YOU CAN RESET YOUR PASSWORD")}/>)}
                    {!isResetPasswordEmailNotRegistered && !isResetPasswordEmailSent && (<LoginResetPassword checkEmailForPasswordReset={checkEmailForPasswordReset} registeredUserEmail={email}/>)}
                </Modal>)}
        </div>);
};
export const LoginContent = observer(LoginContentComponent);
