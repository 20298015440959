import { JCRichText } from "@/components/RichText/RichText";
import { useNotFound } from "@/lib/prismicio/useNotFound";
import { Link, NextImage, useI18n } from "@jog/react-components";
import { isFilled } from "@prismicio/helpers";
import { AlertBox, ArrowButton, ArrowLink, Container } from "components";
export const PageNotFoundContent = () => {
    const { $t } = useI18n();
    const prismicContent = useNotFound();
    return (<Container className="mb-28 sm:max-xl:min-w-full">
            <div className="px-6.25 lg:px-0">
                <AlertBox type="error" text={$t("404 Page Not Found")}/>
            </div>
            <div className="flex flex-wrap px-6.25 lg:flex-nowrap lg:px-0">
                <div className="basis-full lg:basis-7/12">
                    <h1 className="my-5.5 font-primary text-22 font-normal">{prismicContent.title}</h1>
                    <h2 className="mx-0 mt-0 mb-5.5 font-primary text-base font-normal">{prismicContent.subtitle}</h2>
                    <div className="my-5.5 text-base">
                        {isFilled.richText(prismicContent.description) && (<JCRichText linkClass="text-gray-base no-underline hover:text-orange-main cursor-pointer" field={prismicContent.description}/>)}
                    </div>
                    {prismicContent.links.map((link, index) => (<ArrowLink key={index} marginBot="10px" text={link.name} href={link.link}/>))}
                </div>
                <div className="basis-full p-0 lg:basis-5/12">
                    {prismicContent.image ? (<NextImage blur={false} src={prismicContent.image.src} alt={prismicContent.image.alt} className="h-auto w-full" width={prismicContent.image.width} height={prismicContent.image.height}/>) : null}
                </div>
            </div>
            <div className="px-6.25 lg:px-0">
                <Link to="/">
                    <ArrowButton className="my-8 text-center">{$t("Continue shopping")}</ArrowButton>
                </Link>
            </div>
        </Container>);
};
