import { styled } from "@jog/theming";
const JcFriendCardWrapper = styled.div `
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 20px 20px 20px 0;
    @media screen and (max-width: 424px) {
        flex-flow: column nowrap;
        padding: 20px 0;
    }

    .points-overview {
        background-color: #809a7b;
        color: white;
        padding: 20px;
        margin-right: 20px;
        text-align: center;
        @media screen and (max-width: 424px) {
            margin-right: 0;
            margin-bottom: 15px;
        }
    }

    .profile-overview {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        p {
            margin-bottom: 0;
        }

        @media screen and (max-width: 580px) {
            p {
                font-size: 12px;
            }
        }
        @media screen and (max-width: 424px) {
            text-align: center;
        }
    }
`;
export default JcFriendCardWrapper;
